import { Component, OnInit, ViewChild } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { MatDialog, MatSort, MatPaginator, MatTableDataSource } from '@angular/material';
import { Router, ActivatedRoute } from '@angular/router';
import { User } from 'src/app/models/user.model';
import { LoadDialogComponent } from 'src/app/dialog/load-dialog/load-dialog.component';
import { LanguageService } from 'src/app/language/language.service';
import { HttpErrorResponse } from '@angular/common/http';
import { ValidErrorDialogComponent } from 'src/app/dialog/valid-error-dialog/valid-error-dialog.component';
import { Exam } from 'src/app/models/exam.model';
import { UserService } from 'src/app/services/user.service';
import { ConfirmationDialogComponent } from 'src/app/dialog/confirmation-dialog/confirmation-dialog.component';
import { SchoolLevel } from 'src/app/models/schoolLevel.model';
import { ProfessionalExperience } from 'src/app/models/professionalExperience.model';
import { ProfessionalExperienceService } from 'src/app/services/professionalExperience.service';
import { SchoolLevelService } from 'src/app/services/schoolLevel.service';
import { StatusService } from 'src/app/services/status.service';
import { RecruiterService } from 'src/app/services/recruteur.service';
@Component({
  selector: 'app-update-recruteur',
  templateUrl: './update-recruteur.component.html',
  styleUrls: ['./update-recruteur.component.scss']
})
export class UpdateRecruteurComponent  implements OnInit {

  user: User;
  cnxForm: FormGroup;
  error: string;
  hasError: boolean;

  schoolLevels: SchoolLevel[];
  professionalExperiences: ProfessionalExperience[];

  private exams: Exam[];
  dataSource: MatTableDataSource<Exam>;

  @ViewChild(MatPaginator, {static: true}) paginator: MatPaginator;
  @ViewChild(MatSort, {static: true}) sort: MatSort;

  constructor(
    private formBuilder: FormBuilder,
    public dialog: MatDialog,
    private translate: LanguageService,
    private route: ActivatedRoute,
    private router: Router,
    private userService: UserService,
    private professionalExperienceService: ProfessionalExperienceService,
    private schoolLevelService: SchoolLevelService,
    private recruiterService: RecruiterService,
  ) {
    this.exams = [];
    this.dataSource = new MatTableDataSource(this.exams);

    translate.addWebLanguageChangeListener(() => this.refreshSelect());
    this.refreshSelect();
  }

  ngOnInit() {
    this.user = new User();
    this.user.id = this.route.snapshot.params.id;

    this.cnxForm = this.formBuilder.group({
      name: ['', [Validators.required]],
      firstname: ['', [Validators.required]],
      mail: ['', [Validators.required, Validators.email]],
      phoneNumber: ['', [Validators.required]],
      schoolLevel: [''],
      professionalExperience: [''],
    });

    this.refreshUser();

    // Surveillez les changements du formulaire
    this.cnxForm.valueChanges.subscribe(() => {
      this.updated();
    });

    this.dataSource.paginator = this.paginator;
    this.dataSource.sort = this.sort;

    const defaultSort = this.dataSource.sortingDataAccessor;
    this.dataSource.sortingDataAccessor = (passage: Exam, sortHeaderId: string): string | number => {
      let sortAttribut = defaultSort(passage, sortHeaderId);

      switch (sortHeaderId) {
        case 'name': sortAttribut = passage.test.name; break;
        case 'level': sortAttribut = passage.test.level.name; break;
        case 'datePassage': sortAttribut = passage.dateStartPassage.getTime(); break;
        case 'timePassage': sortAttribut = passage.getTime(); break;
      }

      return sortAttribut;
    };
  }

  refreshSelect() {
    this.professionalExperienceService.getList().subscribe(professionalExperiences => {
      this.professionalExperiences = professionalExperiences;
    });

    this.schoolLevelService.getList().subscribe(schoolLevels => {
      this.schoolLevels = schoolLevels;
    });
  }

  updated() : boolean {
    let updated = false;

    if (this.cnxForm.get('name').value !== this.user.name) {
      updated = true;
    }
    if (this.cnxForm.get('firstname').value !== this.user.firstname) {
      updated = true;
    }
    if (this.cnxForm.get('mail').value !== this.user.mail) {
      updated = true;
    }
    if (this.cnxForm.get('phoneNumber').value !== this.user.getPhoneNumberFrenchFormat()) {
      updated = true;
    }
    if (this.cnxForm.get('schoolLevel').value !== this.user.schoolLevel) {
      updated = true;
    }
    if (this.cnxForm.get('professionalExperience').value !== this.user.professionalExperience) {
      updated = true;
    }

    return updated;
  }

  refreshUser() {
    this.userService.getById(this.user.id).subscribe(user => {
      this.user = user;
      this.cnxForm.get('name').setValue(this.user.name);
      this.cnxForm.get('firstname').setValue(this.user.firstname);
      this.cnxForm.get('mail').setValue(this.user.mail);
      this.cnxForm.get('phoneNumber').setValue(this.user.getPhoneNumberFrenchFormat());
      this.cnxForm.get('schoolLevel').setValue(this.user.schoolLevel);
      this.cnxForm.get('professionalExperience').setValue(this.user.professionalExperience);

      this.exams.splice(0, this.exams.length);
      this.exams.push(...user.passages);
      this.dataSource.filter = '';
    }, (error: HttpErrorResponse) => {
      switch (error.status) {
        case 400:
        case 404:
          this.router.navigate(['recruiters']);
      }
    });
  }

  isSameSchoolLevel(o1: SchoolLevel, o2: SchoolLevel) {
    if (o1 == null || o2 == null) {
      return false;
    }

    return o1.equals(o2);
  }
  
  isSameProfessionalExperience(o1: ProfessionalExperience, o2: ProfessionalExperience) {
    if (o1 == null || o2 == null) {
      return false;
    }

    return o1.equals(o2);
  }


  onSubmit() {
    const dialog = this.dialog.open(LoadDialogComponent, {
      disableClose: true,
      data: {title: this.translate.getWord('update.inProgress')}
    });

    this.user.name = this.cnxForm.get('name').value.toUpperCase();
    this.user.firstname = this.cnxForm.get('firstname').value;
    this.user.mail = this.cnxForm.get('mail').value.toLowerCase();
    this.user.setPhoneNumberFrenchFormat(this.cnxForm.get('phoneNumber').value);
    this.user.schoolLevel = this.cnxForm.get('schoolLevel').value;
    this.user.professionalExperience = this.cnxForm.get('professionalExperience').value;

    this.userService.update(this.user).subscribe(() => {
      dialog.close();
      this.hasError = false;

      this.dialog.open(ValidErrorDialogComponent, {
        data: {title: this.translate.getWord('update.valid'), state: 1}
      });
       // Retour à la liste des tests
       this.router.navigate(['recruiters']);
    },
    (error: HttpErrorResponse) => {
      dialog.close();
      this.error = error.error.message === undefined ? this.translate.getWord('network.error') : error.error.message;
      this.hasError = true;
    });
  }

  backToPage() {
    if (history.length > 1) {
      history.back();
    } else {
      window.close();
    }
  }

  /* onDelete() {
    this.dialog.open(ConfirmationDialogComponent, {
      disableClose: true,
      data: {title: this.translate.getWord('recruiter.delete')}
    })
    .afterClosed().subscribe(result => {
      if (result) {
        const loadDialog = this.dialog.open(LoadDialogComponent, {
          disableClose: true,
          data: {title: this.translate.getWord('delete.inProgress')}
        });

        this.user.status = StatusService.SUSPENDED;
        this.recruiterService.suspendRecruiter(this.user).subscribe(() => {
          loadDialog.close();
          this.router.navigate(['recruiters']);
        },
        (error: HttpErrorResponse) => {
          loadDialog.close();
          this.error = error.error.message === undefined ? this.translate.getWord('network.error') : error.error.message;
          this.hasError = true;
        });
      }
    });
  } */

 
}
