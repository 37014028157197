import { Component, OnChanges, SimpleChanges } from '@angular/core';
import { ApiService } from 'src/app/services/api.service';
import { ActivatedRoute, Router } from '@angular/router';


@Component({
  selector: 'app-change-password',
  templateUrl: './change.password.component.html',
  styleUrls: ['./change.password.component.scss']
})
export class ChangePasswordComponent {
  newPassword: string;
  confirmPassword: string;
  id: number; 
  hasError: boolean;

  constructor(private apiService: ApiService, private route: ActivatedRoute , private router: Router) {
    this.id = this.route.snapshot.params['id'];
  }

  onKeyUp(event: KeyboardEvent): void {
    if(this.newPassword !== undefined && this.confirmPassword !== undefined){
      if(this.newPassword !== this.confirmPassword)
        this.hasError = true
      else 
        this.hasError = false
    } 
  }

  changePassword() {
    this.id = this.route.snapshot.params['id'];
    // console.log('id', this.id)
    if (this.newPassword === this.confirmPassword) {
    this.apiService.changePassword(this.id, this.newPassword).subscribe(
      (response) => console.log('Mot de passe changé avec succès.', response)
    );
    } else {
      console.error('Les mots de passe ne correspondent pas.');
    }
    this.router.navigate(['login']);
  }
}


