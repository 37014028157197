import { HttpErrorResponse } from '@angular/common/http';
import { Component, OnInit, ViewChild } from '@angular/core';
import { MatDialog, MatPaginator, MatSort, MatTableDataSource } from '@angular/material';
import { Subscription } from 'rxjs';
import { ConfirmationDialogComponent } from 'src/app/dialog/confirmation-dialog/confirmation-dialog.component';
import { LoadDialogComponent } from 'src/app/dialog/load-dialog/load-dialog.component';
import { ValidErrorDialogComponent } from 'src/app/dialog/valid-error-dialog/valid-error-dialog.component';
import { LanguageService } from 'src/app/language/language.service';
import { Technology } from 'src/app/models/technology.model';
import { TechnologyService } from 'src/app/services/technology.service';
import { ResponsiveService } from 'src/app/tools/responsive.service';

@Component({
  selector: 'app-list-technology',
  templateUrl: './list-technology.component.html',
  styleUrls: ['./list-technology.component.scss']
})
export class ListTechnologyComponent implements OnInit {

  private technologies: Technology[];
  private dataSource: MatTableDataSource<Technology>;
  private technologiesRequest: Subscription;
  
  @ViewChild(MatPaginator, {static: true}) paginator: MatPaginator;
  @ViewChild(MatSort, {static: true}) sort: MatSort;
 // dialog: any;
 // translate: any;
  userService: any;
  hasError: boolean;
  router: any;
  error: any;
  
  constructor(private technologieService: TechnologyService,private responsiveService: ResponsiveService,
    private dialog: MatDialog, private translate: LanguageService,) { 
    this.technologies = [];
    this.dataSource = new MatTableDataSource(this.technologies);

    // Pour refrechir la liste des technologies
    this.refreshTechnologiesList();
  }

  ngOnInit() {

    // Appel aux paginations
    this.dataSource.paginator = this.paginator;
    this.dataSource.sort = this.sort;

    // Filtrer la technologie avec son nom
    this.dataSource.filterPredicate = (technology: Technology, filter: string) => {
      filter = filter.trim().toLowerCase();
      const name = technology.name.toLowerCase();
      if (name.search(filter) >= 0) {
        return true;
      }
      return false;
    };
  }

  getTechnologiesDisplayedColumns(): string[] {
    return this.responsiveService.isOnPortableScreen()
      ? [ 'name', 'action']
      : [ 'name', 'action'];
  }

  applyFilter(filterValue: string) {
    this.dataSource.filter = filterValue;
  }

   // Pour récupérer la liste des technologies
  refreshTechnologiesList(){
    if (this.technologiesRequest) {
      this.technologiesRequest.unsubscribe();
    }

    this.technologiesRequest = this.technologieService.getList()
        .subscribe(technologies => {
          this.technologies.splice(0, this.technologies.length);
          this.technologies.push(...technologies);
          this.dataSource.filter = '';
      });
  }

  // pour supprimer une technologie
  onDeleteTechnology(technology: Technology) {
    this.dialog.open(ConfirmationDialogComponent, {
      disableClose: true,
      data: {title: this.translate.getWord('technology.delete')}
    })
    .afterClosed().subscribe(result => {
      if (result) {
        const loadDialog = this.dialog.open(LoadDialogComponent, {
          disableClose: true,
          data: {title: this.translate.getWord('delete.inProgress')}
        });
  
        this.technologieService.delete(technology).subscribe(() => {
          loadDialog.close();
          this.hasError = false;
  
          this.dialog.open(ValidErrorDialogComponent, {
            data: {title: this.translate.getWord('delete.valid'), state: 1}
          })
          .afterClosed().subscribe(() => window.location.reload());
        
        },
        (error: HttpErrorResponse) => {
  
          loadDialog.close();
          switch (error.status) {
            case 500:
            case 404:
              this.dialog.open(ValidErrorDialogComponent, {
                data: {title: this.translate.getWord('Nous pouvons pas supprimer technologie affecté à un test')}
              })
          }
        });
      }
    });
  }

   // customiser la pagination dans la table des technologies
   nbTechnosPage: number = 100;
   getPageSizeOptions(): number[] {
    if (this.dataSource.paginator.length > this.nbTechnosPage){
      return [5, 10, 25, 50, this.nbTechnosPage,  this.dataSource.paginator.length];
    }
    else{
      return [5, 10, 25, 50, this.nbTechnosPage];
    }
  }
}
