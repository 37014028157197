import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { BehaviorSubject, Observable } from 'rxjs';
import { Language } from 'src/app/models/language.model';
import { TranslateService } from '@ngx-translate/core';

@Injectable({
  providedIn: 'root'
})
export class LanguageService {

  private languagesSubject: BehaviorSubject<Language[]>;
  public languages: Observable<Language[]>;
  private actualLanguage: BehaviorSubject<Language>;

  constructor(private http: HttpClient, private translate: TranslateService) {
    this.languagesSubject = new BehaviorSubject<Language[]>(JSON.parse(sessionStorage.getItem('languages')));
    this.languages = this.languagesSubject.asObservable();
    this.actualLanguage = new BehaviorSubject<Language>(this.getBrowserLanguage());

    if (document.cookie === '') {
      localStorage.removeItem('language');
      document.cookie = 'new=1;';
    }

    setTimeout(() => { // Permet a aux intercepteurs HTTP d'avoir une instance de ce service sinon null
      translate.setDefaultLang('fr');
      if (this.getWebLanguage() != null) {
        translate.use(this.getWebLanguage().format.toLowerCase());
      }

      this.refreshLanguages();
    }, 0);
  }

   refreshLanguages() {
    this.http.get<Language[]>('/PolymatheeWeb/languages')
      .subscribe(languages => {
          if (languages) {
            languages.forEach((l, i, tab) => tab[i] = new Language(l));

            sessionStorage.setItem('languages', JSON.stringify(languages));
            this.languagesSubject.next(languages);

            const lang = this.getLanguageByFormat(localStorage.getItem('language'));

            this.setWebLanguage(lang ? lang : this.getBrowserLanguage());
          }
    });
  } 

  getLanguageByFormat(langFormat: string): Language {
    const languages = this.getLanguages();

    if (langFormat && languages && languages.length > 0) {
      for (const l of languages) {
        if (l.format === langFormat) {
          return l;
        }
      }
    }

    return null;
  }

  getBrowserLanguage(): Language {
    const languages = this.getLanguages();

    if (languages && languages.length > 0) {
      const browser = this.translate.getBrowserLang().toUpperCase();
      let language = languages[0];

      for (const l of languages) {
        if (browser.match(l.format)) {
          language = l;
          break;
        }
      }

      return language;
    } else {
      return null;
    }
  }

  getLanguages(): Language[] {
    return this.languagesSubject.value;
  }

  getWebLanguage(): Language {
    return this.actualLanguage.value;
  }

  setWebLanguage(language: Language) {
    this.actualLanguage.next(language);
    this.translate.use(language.format.toLowerCase());
    localStorage.setItem('language', language.format);
  }

  addWebLanguageChangeListener(observer) {
    this.translate.onLangChange.subscribe(observer);
  }

  getWord(reference: string, words?: string[]): string {
    return this.translate.instant(reference, words);
  }

  getWordAsyn(reference: string, words?: string[]): Observable<string> {
    return this.translate.get(reference, words);
  }
}
