import { BrowserModule, Title } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { NgbDropdownModule } from '@ng-bootstrap/ng-bootstrap';
import { CountdownModule } from 'ngx-countdown';
import { MaterialModule } from './material.module';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { AppRoutingModule } from './app-routing.module';
import { MenuModule } from './menu/menu.module';
import { AuthGuard } from './auth/guards/auth-guard.service';
import { NgTerminalModule } from 'ng-terminal';
import { AppComponent } from './app.component';
import { SigninComponent } from './auth/signin/signin.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { DashBoardComponent } from './home/dash-board/dash-board.component';
import { HttpClient } from '@angular/common/http';
import { TranslateModule, TranslateLoader, TranslateCompiler } from '@ngx-translate/core';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { LanguageComponent } from './language/language.component';
import { LoadDialogComponent } from './dialog/load-dialog/load-dialog.component';
import { ToolbarComponent } from './toolbar/toolbar.component';
import { SidenavService } from './tools/sidenav.service';
import { ValidErrorDialogComponent } from './dialog/valid-error-dialog/valid-error-dialog.component';
import { UserActivationComponent } from './users/user-activation/user-activation.component';
import { NoAuthGuard } from './auth/guards/no-auth-guard.service';
import { WaitCandidateComponent } from './users/wait-candidate/wait-candidate.component';
import { ResponsiveService } from './tools/responsive.service';
import { CandidateComponent } from './users/candidate/candidate.component';
import { RegisterCandidateComponent } from './users/register-candidate/register-candidate.component';
import { ViewUserComponent } from './users/view-user/view-user.component';
import { CreateQuestionComponent } from './questions/create-question/create-question.component';
import { ViewQuestionnaireComponent } from './questions/view-question/view-question.component';
import { TranslateMessageFormatCompiler } from 'ngx-translate-messageformat-compiler';
import { ListQuestionComponent } from './questions/list-question/list-question.component';
import { ConfirmationDialogComponent } from './dialog/confirmation-dialog/confirmation-dialog.component';
import { ListTestsComponent } from './test/list-test/list-test.component';
import { AddTestComponent } from './test/add-test/add-test.component';
import { TestAssignmentDialogComponent } from './dialog/test-assignment-dialog/test-assignment-dialog.component';
import { LanguageHTTPInterceptor } from './language/language.http-interceptor';
import { PassExamComponent } from './exam/pass-exam/pass-exam.component';
import { TrimPipe } from './tools/trim.pipe';
import { ListExamComponent } from './exam/list-exam/list-exam/list-exam.component';
import { ErrorHTTPInterceptor } from './tools/error.http-interceptor';
import { DashBoardAdminComponent } from './home/dash-board-admin/dash-board-admin.component';
import { DashBoardCandidateComponent } from './home/dash-board-candidate/dash-board-candidate.component';
import { DashBoardRecruiterComponent } from './home/dash-board-recruiter/dash-board-recruiter.component';
import { MatSortModule } from '@angular/material/sort';
import { NgWizardModule, NgWizardConfig, THEME } from 'ng-wizard';
import { UserProfileComponent } from "./users/user-profile/user-profile.component";
import { UpdateTestComponent } from './test/update-test/update-test.component';
import { ListTechnologyComponent } from './technology/list-technology/list-technology.component';
import { UpdateTechnologyComponent } from './technology/update-technology/update-technology.component';
import { AddTechnologyComponent } from './technology/add-technology/add-technology.component';
import { RecruteurComponent } from './users/recruteur/recruteur.component';
import { AddRecruteurComponent } from './users/add-recruteur/add-recruteur.component';
import { UpdateRecruteurComponent } from './users/update-recruteur/update-recruteur.component';
import { AnswersComponent } from './answer/answers/answers.component';
import { ExamDetailsComponent } from './exam-details/exam-details.component';
import { ExamGuard } from './services/guards/exam-guard.service';
import { InfoDialogComponent } from './dialog/info-dialog/info-dialog.component';
import { TimePipe } from './tools/time.pipe';
import { CandidateStatsComponent } from './users/candidate-stats/candidate.stats.component';
import { ResetPasswordComponent } from './auth/reset-password/reset.password.component';
import { ChangePasswordComponent } from './auth/change-password/change.password.component';
import { CreateRandomTestDialogComponent } from './dialog/create-random-test-dialog/create-random-test-dialog.component';
import { ProfilChangePasswordComponent } from './profil/password/profil.change.password.component';
import { PopupToDeleteTestComponent } from './test/list-test/popup-to-delete-test/popup-to-delete-test.component';
import { MatPaginatorIntl } from '@angular/material';
import { CustomMatPaginatorIntl } from './services/custom-datasource';


export function HttpLoaderFactory(httpClient: HttpClient) {
  return new TranslateHttpLoader(httpClient, './assets/language/', '.json');
}

const ngWizardConfig: NgWizardConfig = {
  theme: THEME.arrows,
  toolbarSettings: {
    showNextButton: false,
    showPreviousButton: false,
  }
};

@NgModule({
  declarations: [
    // Composants
    AppComponent,
    SigninComponent,
    DashBoardComponent,
    DashBoardAdminComponent,
    DashBoardRecruiterComponent,
    DashBoardCandidateComponent,
    LanguageComponent,
    ToolbarComponent,
    UserActivationComponent,
    WaitCandidateComponent,
    CandidateComponent,
    RegisterCandidateComponent,
    ViewUserComponent,
    CreateQuestionComponent,
    ViewQuestionnaireComponent,
    ListQuestionComponent,
    ListTestsComponent,
    AddTestComponent,
    ListTestsComponent,
    PassExamComponent,
    UserProfileComponent,
    ProfilChangePasswordComponent,
    // Dialogs
    LoadDialogComponent,
    PopupToDeleteTestComponent,
    ValidErrorDialogComponent,
    ConfirmationDialogComponent,
    InfoDialogComponent,
    TestAssignmentDialogComponent,
    CreateRandomTestDialogComponent,
    TrimPipe,
    TimePipe,
    ListExamComponent,
    UpdateTestComponent,
    ListTechnologyComponent,
    UpdateTechnologyComponent,
    AddTechnologyComponent,
    RecruteurComponent,
    AddRecruteurComponent,
    UpdateRecruteurComponent,
    AnswersComponent,
    ExamDetailsComponent,
    CandidateStatsComponent,
    ResetPasswordComponent,
    ChangePasswordComponent
  ],
  imports: [
    MaterialModule,
    NgTerminalModule,
    FormsModule,
    CountdownModule,
    NgbDropdownModule,
    ReactiveFormsModule,
    BrowserModule,
    AppRoutingModule,
    HttpClientModule,
    MenuModule,
    MatSortModule,
    TranslateModule.forRoot({
      useDefaultLang: true,
      loader: {
        provide: TranslateLoader,
        useFactory: HttpLoaderFactory,
        deps: [HttpClient]
      },
      compiler: {
        provide: TranslateCompiler,
        useClass: TranslateMessageFormatCompiler
      }
    }),
    NgWizardModule.forRoot(ngWizardConfig),
  ],
  entryComponents: [
    LoadDialogComponent,
    PopupToDeleteTestComponent,
    ValidErrorDialogComponent,
    ConfirmationDialogComponent,
    TestAssignmentDialogComponent,
    CreateRandomTestDialogComponent,
    InfoDialogComponent,
  ],
  exports: [
    MatSortModule,
  ],
  providers: [
    // Guards
    AuthGuard,
    NoAuthGuard,
    ExamGuard,

    // Services
    Title,
    SidenavService,
    ResponsiveService,

    // Interceptor
    { provide: HTTP_INTERCEPTORS, useClass: LanguageHTTPInterceptor, multi: true },
    { provide: HTTP_INTERCEPTORS, useClass: ErrorHTTPInterceptor, multi: true },

    // Pipes
    TimePipe,
    //remove label : item per page on datasources paginator
    { provide: MatPaginatorIntl, useClass: CustomMatPaginatorIntl }
  ],
  bootstrap: [
    AppComponent,
  ]
})

export class AppModule { }
