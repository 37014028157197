import { Component, OnInit } from '@angular/core';
import { HttpErrorResponse } from '@angular/common/http';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MatDialog } from '@angular/material';
import { ActivatedRoute, Router } from '@angular/router';
import { LoadDialogComponent } from 'src/app/dialog/load-dialog/load-dialog.component';
import { ValidErrorDialogComponent } from 'src/app/dialog/valid-error-dialog/valid-error-dialog.component';
import { LanguageService } from 'src/app/language/language.service';
import { Technology } from 'src/app/models/technology.model';
import { TechnologyService } from 'src/app/services/technology.service';
import { ConfirmationDialogComponent } from 'src/app/dialog/confirmation-dialog/confirmation-dialog.component';
import { ConstantPool } from '@angular/compiler';

@Component({
  selector: 'app-update-technology',
  templateUrl: './update-technology.component.html',
  styleUrls: ['./update-technology.component.scss']
})
export class UpdateTechnologyComponent implements OnInit {

  error: string;
  hasError: boolean;
  cnxForm: FormGroup;
  technology: Technology;
  technologies: Technology[]=[];


  constructor(private formBuilder: FormBuilder, public dialog: MatDialog,
              private translate: LanguageService,private technologyService: TechnologyService,
              private router: Router,private route: ActivatedRoute) { }

  ngOnInit() {
    this.technology = new Technology();
    this.technology.id = this.route.snapshot.params.id;
    // Validations
    this.cnxForm = this.formBuilder.group({
      name: ['', Validators.required],
    });
    this.refreshTechnology();

    // Surveillez les changements du formulaire
    this.cnxForm.valueChanges.subscribe(() => {
      this.updated();
    });
  }

  updated() : boolean {
    let updated = false;

    if (this.cnxForm.get('name').value !== this.technology.name) {
      updated = true;
    }

    return updated;
  }

  // Pour récupérer la technologie selon son Id
  refreshTechnology(){
      this.technologyService.getById(this.technology.id).subscribe(technology => {
      this.technology = technology;
      this.cnxForm.get('name').setValue(this.technology.name);
    }, (error: HttpErrorResponse) => {
      switch (error.status) {
        case 400:
        case 404:
          this.router.navigate(['technologies']);
      }
    });
  }

  backToPage() {
    if (history.length > 1) {
      history.back();
    } else {
      window.close();
    }
  }

 // Appel à l'API Update et la valider
  onSubmit() {
      const dialog = this.dialog.open(LoadDialogComponent, {
      disableClose: true,
      data: {title: this.translate.getWord('update.inProgress')}
    });
    this.technology.name = this.cnxForm.get('name').value.toUpperCase();
    const technologyExisteDeja = this.technologies.find(techno => techno.name === this.technology.name)
    console.log(technologyExisteDeja)
    if(technologyExisteDeja == undefined){
      this.technologyService.update(this.technology).subscribe(() => {
        dialog.close();

        this.dialog.open(ValidErrorDialogComponent, {
          data: {title: this.translate.getWord('update.valid'), state: 1}
        })
        .afterClosed().subscribe(() => {
          this.router.navigate(['technologies']);
        });
      },
      (error: HttpErrorResponse) => {
        dialog.close();
        this.error = error.error.message === undefined ? this.translate.getWord('network.error') : error.error.message;
      });
    } else {
      dialog.close();
      this.hasError = true;
      this.error = this.translate.getWord('technology.errorAlreadyExist')
    }
  }
 
  // Appel API DELETE ET la valider
  /* onDelete() {
  this.dialog.open(ConfirmationDialogComponent, {
    disableClose: true,
    data: {title: this.translate.getWord('technology.delete')}
  })
  .afterClosed().subscribe(result => {
    if (result) {
      const loadDialog = this.dialog.open(LoadDialogComponent, {
        disableClose: true,
        data: {title: this.translate.getWord('delete.inProgress')}
      });

      this.technologyService.delete(this.technology).subscribe(() => {
        loadDialog.close();
        this.hasError = false;

        this.dialog.open(ValidErrorDialogComponent, {
          data: {title: this.translate.getWord('delete.valid'), state: 1}
        })
        .afterClosed().subscribe(() => this.router.navigate(['technologies']));
      
      },
      (error: HttpErrorResponse) => {

        loadDialog.close();
        switch (error.status) {
          case 500:
          case 404:
            this.dialog.open(ValidErrorDialogComponent, {
              data: {title: this.translate.getWord('Nous pouvons pas supprimer technologie affecté à un test')}
            })
        }
      });
    }
  });
} */
  user(user: any) {
    throw new Error("Method not implemented.");
  }
}

