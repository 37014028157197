import { Component, ViewChild, ElementRef, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { User } from 'src/app/models/user.model';
import { UserService } from 'src/app/services/user.service';
import { MatTableDataSource } from '@angular/material';
import { Exam } from 'src/app/models/exam.model';
import * as html2pdf from 'html2pdf.js';
import { Chart } from 'chart.js';
import { TranslateService } from '@ngx-translate/core';
import { DomSanitizer } from '@angular/platform-browser';

@Component({
  selector: 'app-candidate-stats',
  templateUrl: './candidate.stats.component.html',
  styleUrls: ['./candidate.stats.component.scss']
})

export class CandidateStatsComponent implements OnInit {
  chart: any;
  user: User;
  Nbrquestions = 0;
  timeofallanswers = 0;
  dataSource: MatTableDataSource<Exam>;
  answertrue = 0;
  answerfalse = 0;
  noanswers = 0;
  format = false;

  @ViewChild('content', { static: false })
  content!: ElementRef;
  constructor(private route: ActivatedRoute,
    private userService: UserService,
    private translateService: TranslateService,
    private sanitizer: DomSanitizer) { }

  ngOnInit() {
    this.user = new User();
    this.user.id = this.route.snapshot.params.id;
    this.userService.getById(this.user.id).subscribe(user => {
      this.user = user;

      let trueanswer, falseanswer, notanswers, allanswers, isanswers = 0;

      for (let j = 0; j < this.user.passages.length; j++) {
        this.timeofallanswers += user.passages[j].test.time
        for (let i = 0; i < this.user.passages[j].answers.length; i++) {
          for (let n = 0; n < this.user.passages[j].test.questions.length; n++) {
            allanswers++
          }

          if (this.user
            && this.user.passages
            && this.user.passages[j]
            && this.user.passages[j].answers
            && this.user.passages[j].answers[i]
            && this.user.passages[j].answers[i].propositions) {

            for (let k = 0; k < this.user.passages[j].answers.length; k++) {
              isanswers++;
            }

            for (let f = 0; f < this.user.passages[j].answers[i].propositions.length; f++) {
              if (this.user.passages[j].answers[i].propositions[f] && this.user.passages[j].answers[i].propositions[f].just === true) {
                trueanswer++
              } else {
                falseanswer++
              }
            }
          }
        }
      }
      notanswers = allanswers - isanswers
      this.Nbrquestions = allanswers;
      this.answertrue = trueanswer;
      this.answerfalse = falseanswer;
      this.noanswers = notanswers;

      this.translateService.get(['question.success', 'question.fail', 'question.noresponse']).subscribe(translations => {
        this.chart = new Chart('canvas', {
          type: 'pie',
          data: {
            labels: [translations['question.success'], translations['question.fail'], translations['question.noresponse']],
            datasets: [{
              data: [trueanswer, falseanswer, notanswers],
              backgroundColor: ['turquoise', '#ffd7e1', '#e6f3fe'],
            }]
          }
        });
      });
    })
  }

  makePDF() {
    this.format = true;
    const content = this.content.nativeElement;
    const options = {
      filename: 'rapportexam.pdf',
      image: { type: 'jpeg', quality: 0.98 },
      html2canvas: { scale: 2 },
      jsPDF: { unit: 'mm', format: 'a4', orientation: 'portrait' }
    };

    html2pdf().from(content).set(options).save();
    // Restoring the old style after downloading the PDF
    setTimeout(() => {
      this.format = false;
    }, 1000);
  }

  newPageRequired(index: number) {
    if (index == 3) {
      return true;
    }
    if (index >= 3 && (index - 3) % 6 == 0) {
      return true;
    }
    return false;
  }

  getTechnologies(exam: any) {
    let technos = exam.test.questions.map(question => question.technology.name);
    let technosUnique = [...new Set(technos)];
    return technosUnique.join('/');
  }

  onMarkColoring(examen: Exam): any {
    let examStatus: string = examen.statutExam.name;
    if (examStatus === "Fini") {
      if (examen.mark >= 60) {
        return this.sanitizer.bypassSecurityTrustStyle("font-weight:bold; color: green;");
      }
    }
  }
}