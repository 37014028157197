import { Component, OnChanges, OnInit, SimpleChanges, ViewChild } from '@angular/core';
import { FormGroup, FormBuilder, Validators, FormsModule } from '@angular/forms';
import { MatDialog, MatCheckboxModule } from '@angular/material';
import { LoadDialogComponent } from 'src/app/dialog/load-dialog/load-dialog.component';
import { LanguageService } from 'src/app/language/language.service';
import { HttpErrorResponse } from '@angular/common/http';
import { ValidErrorDialogComponent } from 'src/app/dialog/valid-error-dialog/valid-error-dialog.component';
import { TestService } from 'src/app/services/test.service';
import { Test } from '../../models/test.model';
import { Level } from '../../models/level.model';
import { LevelService } from 'src/app/services/level.service';
import { TechnologyService } from 'src/app/services/technology.service';
import { Technology } from '../../models/technology.model';
import { TypeQuestion } from '../../models/typeQuestion';
import { TypeQuestionService } from '../../services/typequestion.service';
import { Question } from '../../models/question.model';
import { QuestionService } from '../../services/question.service';
import { MatPaginator, MatTableDataSource, MatSort } from '@angular/material';
import { ResponsiveService } from 'src/app/tools/responsive.service';
import { Router } from '@angular/router';
import { StepChangedArgs, NgWizardService } from 'ng-wizard';
import { Subscription } from 'rxjs';

@Component({
  selector: 'app-add-test',
  templateUrl: './add-test.component.html',
  styleUrls: ['./add-test.component.scss'],
})
export class AddTestComponent implements OnInit {

  nameLevelFormGroup: FormGroup;
  technosFormGroup: FormGroup;
  technoNbFormGroup: FormGroup;

  error: string;
  hasError: boolean;

  levels: Level[];
  technos: Technology[];
  typeQuestions: TypeQuestion[];
  test:Test;
  lessOneAffectedQuestionToTest: boolean = false;

  loadedQuestion = false;
  selectedQuestion = false;
  questions: Question[];
  testDuration: number;
  dataSource: MatTableDataSource<Question>;

  @ViewChild(MatPaginator, {static: true}) paginator: MatPaginator;
  @ViewChild(MatSort, {static: true}) sort: MatSort;

  levelTitle: string;
  technologiesTitle: string;
  questionsTitle: string;
  summaryTitle: string;

  questionsListSub: Subscription;

  constructor(private formBuilder: FormBuilder, public dialog: MatDialog, public formsModule: FormsModule, public checkboxModule: MatCheckboxModule, 
              private translate: LanguageService, private levelService: LevelService, private technologyService: TechnologyService,
              private typeQuestionService: TypeQuestionService, private questionService: QuestionService,
              private testService: TestService, private responsiveService: ResponsiveService, private router: Router,
              private stepperService: NgWizardService) {

    this.questions = [];
    this.dataSource = new MatTableDataSource(this.questions);

    this.technologyService.getList().subscribe(technos => {
      this.technos = technos;
    });

    translate.addWebLanguageChangeListener(() => this.onLanguageChange());

    this.onLanguageChange();
  }

  toAffectQuestionToTest(){
    console.log("CHANGEMENT ETAT");
    if(this.questions.filter((question) => question.checkedToAssignedTest == true).length>=0){
      this.lessOneAffectedQuestionToTest = true ;
      console.log("QUESTION AFFECTE");
    } else {
      this.lessOneAffectedQuestionToTest = false ; 
      console.log("TEST VIDE");
    }
  }

  ngOnInit() {
    this.dataSource.paginator = this.paginator;
    this.dataSource.sort = this.sort;

    this.nameLevelFormGroup = this.formBuilder.group({
      name: ['', Validators.required],
      level: ['', Validators.required]
    });

    this.technosFormGroup = this.formBuilder.group({
      technos: ['', Validators.required]
    });

    this.technoNbFormGroup = this.formBuilder.group({});
  }

  verifyCheckbox(event): void {
    let questions = this.questions.filter((question) => question.checkedToAssignedTest === true);
    console.log("Taille question " + questions.length);
    if(questions.length >= 1){
      this.selectedQuestion = true ;
    } else if (!event.checked){
      this.selectedQuestion = false ;
    }
  }


  isSameTypeQuestion(o1: TypeQuestion, o2: TypeQuestion) {
    return o1.equals(o2);
  }

  getQuestionsDisplayedColumns(): string[] {
    return this.responsiveService.isOnPortableScreen()
      ? ['id', 'question', 'technology', 'type','time']
      : ['id', 'question', 'technology', 'type','time'];
  }

  onLanguageChange() {
    this.levelService.getList().subscribe(levels => {
      this.levels = levels;
    });

    this.typeQuestionService.getList().subscribe(typeQuestions => {
      this.typeQuestions = typeQuestions;
    });

    this.levelTitle = this.translate.getWord('word.level');
    this.technologiesTitle = this.translate.getWord('word.technologies');
    this.questionsTitle = this.translate.getWord('word.questions');
    this.summaryTitle = this.translate.getWord('word.recapitulatif');
  }

  getName() {
    return this.nameLevelFormGroup.get('name').value;
  }

  getLevel() {
    return this.nameLevelFormGroup.get('level').value;
  }

  getTechnologie(idStr: string) {
    const id = parseInt(idStr, 10);
    return this.technos.find(t => t.id === id);
  }

  getTechnoNbNames() {
    return Object.keys(this.technoNbFormGroup.controls);
  }

  getTechnoNbControl(name: string) {
    return this.technoNbFormGroup.controls[name];
  }

  deleteTechnoNbControl(name: string) {
    return this.technoNbFormGroup.removeControl(name);
  }

  addTechnoNb(id: string) {
    this.technoNbFormGroup.addControl(id, this.formBuilder.group({
      nb: [0, [Validators.required, Validators.min(1)]],
      typeQuestion: ['', [Validators.required]],
    }));
  }

  onLanguageSelectionChange() {
    const technosChoice: Technology[] = this.technosFormGroup.get('technos').value;

    this.getTechnoNbNames().forEach(technoNbName => {
      const i = technosChoice.findIndex(t => t.id.toString() === technoNbName);

      if (i >= 0) {
        technosChoice.splice(i, 1);
      } else {
        this.deleteTechnoNbControl(technoNbName);
      }
    });

    technosChoice.forEach(t => {
      this.addTechnoNb(t.id.toString());
    });
  }

  goPreviousStep() {
    this.stepperService.previous();
  }

  goNextStep() {
    this.stepperService.next();
  }

  onStepperSelectionChange(event: StepChangedArgs) {
    if (event.step.index === 2) {
      this.getQuestions();
    } else if (this.questionsListSub != null && !this.questionsListSub.closed) {
      this.questionsListSub.unsubscribe();
    }
  }

  getQuestions() {
    const technologies: Technology[] = [];
    const nbQuestions: number[] = [];
    const typeQuestions: TypeQuestion[][] = [];

    this.getTechnoNbNames().forEach(technoNbName => {
      const technoControl = this.getTechnoNbControl(technoNbName);

      technologies.push(this.getTechnologie(technoNbName));
      nbQuestions.push(technoControl.get('nb').value);
      typeQuestions.push(technoControl.get('typeQuestion').value);
    });

    this.questions.splice(0, 100);
    this.loadedQuestion = false;
    this.dataSource.filter = '';

    this.questionsListSub = this.questionService.getList(this.getLevel(), technologies).subscribe(questions => {
      this.questions.push(...questions);
      this.testDuration = questions.reduce((a, b) => a + b.estimatedTime, 0)
      this.dataSource.filter = '';
      this.loadedQuestion = true;
    }, (error: HttpErrorResponse) => {
      this.stepperService.previous();
      this.loadedQuestion = false;
      this.dialog.open(ValidErrorDialogComponent, {
        data: {state: -1, title: 'Erreur', description: error.error.message }
      });
    });
  }

  onSubmit() {
    const dialog = this.dialog.open(LoadDialogComponent, {
      disableClose: true,
      data: {title: this.translate.getWord('create.inProgress')}
    });

    const questionnaire = new Test();

    questionnaire.name = this.getName();
    questionnaire.level = this.getLevel();
    questionnaire.questions = this.questions.filter((question) => question.checkedToAssignedTest === true);

    if(questionnaire.questions.length > 0){
      this.lessOneAffectedQuestionToTest = true ;
    } else {
      this.lessOneAffectedQuestionToTest = false ; 
    }

    this.testService.save(questionnaire).subscribe(() => {
      dialog.close();

      this.dialog.open(ValidErrorDialogComponent, {
        data: {title: this.translate.getWord('test.createtest'), state: 1}
      })
      .afterClosed().subscribe(() => {
        this.router.navigate(['tests']);
      });
    },
    (error: HttpErrorResponse) => {
      dialog.close();
      this.error = error.error.message === undefined ? this.translate.getWord('network.error') : error.error.message;
      this.hasError = true;
    });
  }

  backToPage() {
    if (history.length > 1) {
      history.back();
    } else {
      window.close();
    }
  }

}
