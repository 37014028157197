import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { map } from 'rxjs/operators';
import { Observable } from 'rxjs';
import { Exam } from '../models/exam.model';
import { Test } from '../models/test.model';
import { StatusExam } from '../models/status-exam.model';
import { Answer } from '../models/answer.model';

@Injectable({
  providedIn: 'root'
})
export class ExamService {

  private static URL = '/PolymatheeWeb/exams';

  private isFraud: boolean ;

  constructor(private http: HttpClient) {}

  getById(id: number): Observable<Exam> {
    return this.http.get<Exam>(ExamService.URL + '/' + id)
      .pipe(map(q => {
        return new Exam(q);
      }));
  }

  getList(test: Test, statutExams: StatusExam[][]): Observable<Exam[]> {
    let url = ExamService.URL + '?';

    if (test)
      url += '&test=' + test.id;

    if (statutExams)
      statutExams.forEach(statutExam => {
        url += '&statutExams=' + statutExam.map(tq => tq.id).join(',');
      });

    return this.http.get<Exam[]>(url)
      .pipe(map(exams => {
        exams.forEach((q, i, t) => t[i] = new Exam(q));
        return exams;
      }));
  }



  getListForRecruiter( recruiterID): Observable<Exam[]> {
    let url = ExamService.URL + '/recruiter';
    url += '?idRecruiter=' + recruiterID ;
    return this.http.get<Exam[]>(url)
      .pipe(map(exams => {
        exams.forEach((q, i, t) => t[i] = new Exam(q));
        return exams;
      }));
  }

  save(exam: Exam): Observable<Exam> {
    return this.http.post<Exam>(ExamService.URL, exam)
      .pipe(map(q => {
        return new Exam(q);
    }));
  }

  /**
   * Assign a whole list of exams to a candidate
   */
  bulkSave(exams: Exam[]): Observable<Exam[]> {
    return this.http.post<Exam[]>(ExamService.URL + '/bulk-save', exams);
  }

  delete(examId: number): Observable<Exam> {
    return this.http.delete<Exam>(ExamService.URL + '/' + examId);
  }

  start(exam: Exam): Observable<Exam> {
    return this.http.post<Exam>(ExamService.URL + '/' + exam.id + '/start', {})
      .pipe(map(q => {
        return new Exam(q);
      }));
  }

  saveAnswer(exam: Exam, answer: Answer) {
    return this.http.post(ExamService.URL + '/' + exam.id + '/questions/' + answer.question.id, answer);
  }

  finish(exam: Exam): Observable<Exam> {
    return this.http.post<Exam>(ExamService.URL + '/' + exam.id + '/finish', {})
      .pipe(map(q => {
        return new Exam(q);
      }));
  }

  fraud(exam: Exam): Observable<Exam> {
    return this.http.post<Exam>(ExamService.URL + '/' + exam.id + '/fraud', {})
      .pipe(map(q => {
        return new Exam(q);
      }));
  }

  setFraud(isFraud: boolean): void{
    this.isFraud = isFraud ;
  }

  getFraud(){
    return this.isFraud ;
  }
}
