import { Component, OnInit } from '@angular/core';
import { FormGroup, Validators, FormBuilder, FormArray } from '@angular/forms';
import { MatDialog } from '@angular/material';
import { LanguageService } from 'src/app/language/language.service';
import { TechnologyService } from 'src/app/services/technology.service';
import { Technology } from 'src/app/models/technology.model';
import { Level } from 'src/app/models/level.model';
import { LevelService } from 'src/app/services/level.service';
import { LoadDialogComponent } from 'src/app/dialog/load-dialog/load-dialog.component';
import { ValidErrorDialogComponent } from 'src/app/dialog/valid-error-dialog/valid-error-dialog.component';
import { HttpErrorResponse } from '@angular/common/http';
import { Question } from 'src/app/models/question.model';
import { Proposition } from 'src/app/models/proposition.model';
import { Router } from '@angular/router';
import { CustomValidator } from 'src/app/tools/validator';
import { QuestionService } from 'src/app/services/question.service';
import { TypeQuestion } from 'src/app/models/typeQuestion';
import { TypeQuestionService } from 'src/app/services/typequestion.service';
import { ImagesService } from 'src/app/services/images-service';
import { v4 as uuid } from 'uuid';
@Component({
  selector: 'app-create-question',
  templateUrl: './create-question.component.html',
  styleUrls: ['./create-question.component.scss']
})
export class CreateQuestionComponent implements OnInit {

  cnxForm: FormGroup;
  qcmForm: FormGroup;
  qcuForm: FormGroup;

  error: string;
  hasError: boolean;

  technologies: Technology[];
  levels: Level[];
  typeQuestions: TypeQuestion[];

  showFileSelectionInput = false;
  files: FileList;

  fileTypes: Array<string> = ['image/png', 'image/jpeg', 'image/jpj'];
  constructor(private formBuilder: FormBuilder, private router: Router, public dialog: MatDialog, private translate: LanguageService,
    private technologyService: TechnologyService, private levelService: LevelService,
    private typeQuestionService: TypeQuestionService, private questionService: QuestionService, public imagesService: ImagesService) {

    this.technologyService.getList().subscribe(technologies => {
      this.technologies = technologies;
    });

    this.loadLevels();

    this.loadTypeQuestions();

    this.translate.addWebLanguageChangeListener(() => {
      this.loadLevels();
      this.loadTypeQuestions();
    })
  }

  ngOnInit() {
    this.cnxForm = this.formBuilder.group({
      name: ['', [Validators.required]],
      code: [''],
      technology: [null, [Validators.required]],
      level: [null, [Validators.required]],
      nbPoints: [1, [Validators.required, Validators.min(1)]],
      timeMinutes: [0, [Validators.required, Validators.min(0)]],
      timeSeconds: [0, [Validators.required, Validators.min(0), Validators.max(59)]],
      type: [null, [Validators.required]],
    }, { validators: CustomValidator.timeMinuteSecondCheck });

    this.qcmForm = this.formBuilder.group({
      propositions: this.formBuilder.array([]),
    });

    this.qcuForm = this.formBuilder.group({
      propositions: this.formBuilder.array([]),
      just: [null, [Validators.required]],
    });
  }

  loadLevels() {
    this.levelService.getList().subscribe(levels => {
      this.levels = levels;
    });
  }

  loadTypeQuestions() {
    this.typeQuestionService.getList().subscribe(typeQuestions => {
      this.typeQuestions = typeQuestions;
    });
  }

  getPropositionsQCM(): FormArray {
    return this.qcmForm.get('propositions') as FormArray;
  }

  getPropositionsQCU(): FormArray {
    return this.qcuForm.get('propositions') as FormArray;
  }

  addProposition() {
    const nameControl = this.formBuilder.control('', Validators.required);

    this.getPropositionsQCM().push(this.formBuilder.group({
      name: nameControl,
      just: [false],
    }));

    this.getPropositionsQCU().push(this.formBuilder.group({
      name: nameControl,
    }));
  }

  deleteProposition(i: number) {
    this.getPropositionsQCM().removeAt(i);
    this.getPropositionsQCU().removeAt(i);
  }

  isValidQuestionRealTime() {
    if (this.cnxForm.invalid) {
      return false;
    }

    if (this.isQCM()) {

      // Pour validite checkbox
      for (const propositionGroup of this.getPropositionsQCM().controls) {
        propositionGroup.updateValueAndValidity();
      }

      if (this.qcmForm.invalid || this.getPropositionsQCM().length < 2) {
        return false;
      }

      let oneJust = false;
      let oneFalse = false;

      for (const propositionGroup of this.getPropositionsQCM().controls) {
        if (propositionGroup.get('just').value) {
          oneJust = true;
        } else {
          oneFalse = true;
        }

        if (oneJust && oneFalse) {
          break;
        }
      }

      if (!oneJust || !oneFalse) {
        return false;
      }
    } else if (this.isSingle()) {
      if (this.qcuForm.invalid || this.getPropositionsQCU().length < 2) {
        return false;
      }

      if (this.getPropositionsQCU().length < 2) {
        return false;
      }
    }

    return true;
  }

  getTypeSelected(): TypeQuestion {
    return this.cnxForm.get('type').value;
  }

  isQCM() {
    const selected = this.getTypeSelected();
    return selected != null && selected.equals(TypeQuestionService.QCM);
  }

  isSingle() {
    const selected = this.getTypeSelected();
    return selected != null && selected.equals(TypeQuestionService.SINGLE);
  }

  onSubmit() {
    // Creating the file name with the uuid
    let fileName: string;
    if (this.files) {
      const fileToUpload = this.files.item(0);
      const extension = fileToUpload.type;
      if (extension && this.fileTypes.includes(extension)) {
        fileName = uuid() + '.' + extension.split('/')[1];
        this.saveImage(this.files, fileName);
      }
    }

    const dialog = this.dialog.open(LoadDialogComponent, {
      disableClose: true,
      data: { title: this.translate.getWord('create.inProgress') }
    });

    const question = new Question();
    question.question = this.cnxForm.get('name').value;
    question.code = this.cnxForm.get('code').value;
    question.technology = this.cnxForm.get('technology').value;
    question.level = this.cnxForm.get('level').value;
    question.question = this.cnxForm.get('name').value;
    question.nbPoints = this.cnxForm.get('nbPoints').value;
    question.setMinutes(this.cnxForm.get('timeMinutes').value);
    question.setSeconds(this.cnxForm.get('timeSeconds').value);
    question.type = this.cnxForm.get('type').value;
    if (fileName) {
      question.imageName = fileName;
    }

    if (this.isQCM()) {
      for (const propositionGroup of this.getPropositionsQCM().controls) {
        const proposition = new Proposition();

        proposition.proposition = propositionGroup.get('name').value;
        proposition.just = propositionGroup.get('just').value ? true : false;

        question.propositions.push(proposition);
      }
    } else if (this.isSingle()) {
      const selected = this.qcuForm.get('just').value;

      for (const propositionGroup of this.getPropositionsQCU().controls) {
        const proposition = new Proposition();

        proposition.proposition = propositionGroup.get('name').value;
        proposition.just = selected === propositionGroup;

        question.propositions.push(proposition);
      }
    }

    this.questionService.save(question)
      .subscribe(questionCreated => {
        dialog.close();
        this.hasError = false;

        const validDialog = this.dialog.open(ValidErrorDialogComponent, {
          data: { title: this.translate.getWord('create.valid'), state: 1 }
        });

        validDialog.afterClosed().subscribe(() => this.router.navigate(['questions', questionCreated.id]));
      },
        (error: HttpErrorResponse) => {
          dialog.close();
          this.error = error.error.message === undefined ? this.translate.getWord('network.error') : error.error.message;
          this.hasError = true;
        });
  }

  backToPage() {
    if (history.length > 1) {
      history.back();
    } else {
      window.close();
    }
  }

  saveFiles(files) {
    this.files = files;
  }

  saveImage(files: FileList, imageName: string): void {

    const fileToUpload = files.item(0);
    const reader = new FileReader();
    let base64String;
    reader.onloadend = () => {
      base64String = reader.result as string;
      base64String = '"' + base64String + '"';
      this.imagesService.uploadImage(base64String, imageName).subscribe(res => { });
    };
    if (fileToUpload) {
      reader.readAsDataURL(fileToUpload);
    }
  }
}