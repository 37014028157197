import { Component, ViewChild, OnInit } from '@angular/core';
import { User } from 'src/app/models/user.model';
import { ResponsiveService } from 'src/app/tools/responsive.service';
import { MatPaginator, MatTableDataSource, MatSort, MatDialog } from '@angular/material';
import { Technology } from 'src/app/models/technology.model';
import { Subscription } from 'rxjs';
import { TechnologyService } from 'src/app/services/technology.service';
import { UserService } from 'src/app/services/user.service';
import { StatusService } from 'src/app/services/status.service';
import { AfterViewInit } from '@angular/core';
import { StatusExamService } from 'src/app/services/status-exam.service';
import { ConfirmationDialogComponent } from 'src/app/dialog/confirmation-dialog/confirmation-dialog.component';
import { LanguageService } from 'src/app/language/language.service';
import { LoadDialogComponent } from 'src/app/dialog/load-dialog/load-dialog.component';
import { ActivatedRoute } from '@angular/router';
import { ValidErrorDialogComponent } from 'src/app/dialog/valid-error-dialog/valid-error-dialog.component';
import { HttpErrorResponse } from '@angular/common/http';

interface Mark {
  technologie: string;
  noteMoyenne: number;
}

@Component({
  selector: 'app-candidate',
  templateUrl: './candidate.component.html',
  styleUrls: ['./candidate.component.scss']
})
export class CandidateComponent implements OnInit, AfterViewInit {
  defaultTechnology = new Technology();

  private candidatesRequest: Subscription;
  private candidates: User[];

  valueSortedNote: number = 0;
  dataSource: MatTableDataSource<User>;
  technologies: Technology[];

  technologiesFilterStatic: Technology[];

  // Filtres
  freshnessFilter = 0;
  technologiesFilter: Technology[];
  noteMinFilter: number;

  @ViewChild(MatPaginator, { static: true }) paginator: MatPaginator;
  @ViewChild(MatSort, { static: true }) sort: MatSort;

  users: User[] = [];
  usersave: User[] = [];

  error: string;
  hasError: boolean;

  constructor(private technologyService: TechnologyService, private userService: UserService,
    private responsiveService: ResponsiveService, private dialog: MatDialog,
    private translate: LanguageService, private route: ActivatedRoute) {
    this.candidates = [];
    this.dataSource = new MatTableDataSource(this.candidates);

    this.technologyService.getList().subscribe(technologies => {
      this.technologies = technologies;
    });

    this.refreshCandidatesList();
  }

  /* Le plus simple pour faire le tri est d'utiliser cette méthode */
  ngAfterViewInit(): void {
    this.dataSource.sort = this.sort;
    this.dataSource.paginator = this.paginator;
  }

  ngOnInit(): void {
  }

  getUsersCandidateDisplayedColumns(): string[] {
    return this.responsiveService.isOnPortableScreen()
      ? ['idCandidat','name', 'firstname', 'email', 'phoneNumber', 'action']
      : ['idCandidat','name', 'firstname', 'email', 'phoneNumber', 'mark', 'action'];
  }

  applyFilter(filterValue: string) {
    this.dataSource.filter = filterValue;
  }

  refreshCandidatesList() {
    if (this.candidatesRequest) {
      this.candidatesRequest.unsubscribe();
    }

    this.candidatesRequest = this.userService.getCandidatesList(StatusService.VALIDATED, true, this.freshnessFilter,
      this.technologiesFilter, this.noteMinFilter).subscribe(users => {
        this.candidates.splice(0, this.candidates.length);
        this.users = users.sort((a, b) => a.name.localeCompare(b.name));
        this.usersave = users.sort((a, b) => a.name.localeCompare(b.name));
        this.candidates.push(...users);
        this.technologiesFilterStatic = this.technologiesFilter;
        this.dataSource.filter = '';
      });
  }

  sortMark() {
    if ((this.valueSortedNote == 0) && (this.users)) {
      this.candidates.splice(0, this.candidates.length);
      this.users.sort((a, b) => b.average - a.average);
      this.candidates.push(...this.users);
      this.technologiesFilterStatic = this.technologiesFilter;
      this.dataSource.filter = '';
      this.valueSortedNote = 1;
    } else if ((this.valueSortedNote == 1) && (this.users)) {
      this.candidates.splice(0, this.candidates.length);
      this.users.sort((a, b) => a.average - b.average);
      this.candidates.push(...this.users);
      this.technologiesFilterStatic = this.technologiesFilter;
      this.dataSource.filter = '';
      this.valueSortedNote = 2;
    } else if ((this.valueSortedNote == 2) && (this.users)) {
      this.candidates.splice(0, this.candidates.length);
      this.users.sort((a, b) => a.name.localeCompare(b.name));
      this.candidates.push(...this.users);
      this.technologiesFilterStatic = this.technologiesFilter;
      this.dataSource.filter = '';
      this.valueSortedNote = 0;
    }
  }

  resetSortMark() {
    this.valueSortedNote = 0;
  }

  resetFilter() {
    this.freshnessFilter = 0;
    this.technologiesFilter = [];
    this.noteMinFilter = null;
    this.refreshCandidatesList();
  }

  getTooltipContent(user: User): string {
    const marks = this.getMarkByTechnology(user);
    let tooltipContent = '';

    for (const mark of marks) {
      tooltipContent += `${mark.technologie}: ${mark.noteMoyenne}%\n`;
    }

    return tooltipContent.trim();
  }

  getMarkFilterTechnologyContent(user: User): string {
    const marks = this.getMarkByTechnology(user);
    let res = '';
    let sum = 0;
    let average = 0;
    let auMoinsUneTechno: boolean = false;

    if (marks.length > 0) {
      for (const mark of marks) {
        if (this.technologiesFilter && this.technologiesFilter.length != 0) {
          if (this.technologiesFilter.length >= 1) {
            for (let i = 0; i < this.technologiesFilter.length; i++) {
              if (mark.technologie == this.technologiesFilter[i].name) {
                auMoinsUneTechno = true;
                if (Number.isNaN(mark.noteMoyenne)) {
                  mark.noteMoyenne = 0;
                }
                average = average + mark.noteMoyenne;
                res = `${(average / this.technologiesFilter.length).toFixed(2)}`;
              } else if (!auMoinsUneTechno) {
                res = 'Pas évalué';
              }
            }
          }
        } else {
          res = user.average + '';
        }
      }
    } else {
      res = 'Pas évalué';
    }
    return res;
  }

  getMarkByTechnology(user: User): Mark[] {
    const passedExams = this.getPassedExams(user);
    const tab = {};

    if (passedExams.length > 0) {
      for (const exam of passedExams) {
        const markByLanguages = this.getMarkByLanguage(exam);
        this.getAllMarkMarkByTechnology(markByLanguages, tab);
      }
    }

    // Convertir tab pour avoir la moyenne des notes pour chaque technologie
    for (const tech in tab) {
      const sum = tab[tech].reduce((a, b) => a + b, 0);
      const avg = sum / tab[tech].length;
      tab[tech] = {
        technologie: tech,
        noteMoyenne: Math.round(avg * 100) / 100  // Arrondi à deux décimales
      };
    }

    return Object.values(tab) as Mark[];  // Convertir le résultat en tableau de type Mark
  }


  getPassedExams(user: User): any[] {
    if (user && user.passages) {
      return user.passages.filter(e => e.statutExam.equals(StatusExamService.FINISHED));
    }
    return [];
  }

  getMarkByLanguage(exam): any[] {
    if (exam.markByLanguage && exam.markByLanguage.length > 0) {
      return exam.markByLanguage;
    }
    return [];
  }

  getAllMarkMarkByTechnology(markByLanguage, tab): { [key: string]: number[] } {
    if (markByLanguage && markByLanguage.length > 0) {
      for (const markTech of markByLanguage) {
        if (!tab[markTech.technology.name]) {
          tab[markTech.technology.name] = [];
        }
        tab[markTech.technology.name].push(markTech.mark);
      }
    }
    return tab;
  }

  maxCanditatsPage: number = 100;
  onPageSizeOptions(): number[] {
    if (this.dataSource.data.length > this.maxCanditatsPage) {
      return [5, 10, 25, 50, this.maxCanditatsPage, this.dataSource.data.length];
    }
    else {
      return [5, 10, 25, 50, this.maxCanditatsPage]
    }
  }

  //pour suspendre un candidat à un test en lui affectant le status suspended
  onCandidateSuspend(user: User) {
    this.dialog.open(ConfirmationDialogComponent, {
      disableClose: true,
      data: { title: this.translate.getWord('candidat.suspend') }
    })
      .afterClosed().subscribe(result => {
        if (result) {
          const loadDialog = this.dialog.open(LoadDialogComponent, {
            disableClose: true,
            data: { title: this.translate.getWord('suspend.inProgress') }
          });

          user.status = StatusService.SUSPENDED;
          this.userService.suspendCandidate(user).subscribe(() => {
            loadDialog.close();
            this.dialog.open(ValidErrorDialogComponent, {
              data: { title: this.translate.getWord('suspend.valid'), state: 1 }
            })
              .afterClosed().subscribe(() => window.location.reload());
          },
            (error: HttpErrorResponse) => {
              loadDialog.close();
              this.error = error.error.message === undefined ? this.translate.getWord('network.error') : error.error.message;
              this.hasError = true;
            });
        }
      });
  }
}
