import { Component, ViewChild, OnInit } from '@angular/core';
import { ResponsiveService } from 'src/app/tools/responsive.service';
import { MatPaginator, MatTableDataSource, MatSort } from '@angular/material';
import { Exam } from 'src/app/models/exam.model';
import { SigninService } from 'src/app/auth/signin/signin.service';
import { StatusExamService } from 'src/app/services/status-exam.service';
import { StatusExam } from 'src/app/models/status-exam.model';
import { LanguageService } from 'src/app/language/language.service';
import {ExamService} from "../../../services/exam.service";
import { AfterViewInit } from '@angular/core';

@Component({
  selector: 'app-list-exam',
  templateUrl: './list-exam.component.html',
  styleUrls: ['./list-exam.component.scss']
})
export class ListExamComponent implements OnInit , AfterViewInit {

  /* Liste des examens */
  private exams: Exam[];
  private tests: Exam[];

  /* Pour pagination */
  dataSource: MatTableDataSource<Exam>;
  @ViewChild(MatPaginator, {static: true}) paginator: MatPaginator;
  @ViewChild(MatSort, {static: true}) sort: MatSort;

  /* Constructeurs */
  constructor(private responsiveService: ResponsiveService, private signinService: SigninService,
     translate: LanguageService, private examService : ExamService) {
    this.exams = [];
    this.dataSource = new MatTableDataSource(this.exams);

    /* Si c'est un recruteur afficher liste des exams */
    this.isRecruiter() ? this.refreshExamsRecruiter() : this.refreshExams();
    /* Pour la traduction */
    translate.addWebLanguageChangeListener(() => this.refreshExams());
  }

    /* Le plus simple pour faire le tri est d'utiliser cette méthode */
    ngAfterViewInit(): void {
      this.dataSource.sort = this.sort;
      this.dataSource.paginator = this.paginator;
    }


  /* Dans ngOnInit on traite la pagination, filtre et recherche des données*/
  ngOnInit(): void {
  }

 /* Rafraîchir liste des examens*/
  refreshExams() {
    this.signinService.refreshUser().subscribe(() => {
      this.exams.splice(0, this.exams.length);
      this.exams.push(...this.signinService.getUser().passages);
      this.dataSource.filter = '';
    });
  }

/* Donner liste des colones*/

  getExamDisplayedColumns(): string[] {
    if(this.isRecruiter()){
   /* Si il est un recruteur affiche ces différents colones*/
      return this.responsiveService.isOnPortableScreen()
      ? [ 'test',  'dateStartPassage', 'dateEndPassage','statutExam', 'candidate', 'action']
      : [ 'test',  'dateStartPassage', 'dateEndPassage', 'statutExam', 'candidate' , 'action'];
     }
    else {
      return this.responsiveService.isOnPortableScreen()
        ? ['test',  'dateStartPassage', 'dateEndPassage',  'statutExam', 'action']
        : [ 'test', 'dateStartPassage', 'dateEndPassage',  'statutExam', 'action'];
    }}

  /* Filtre sur la liste des examens*/
  applyFilter(filterValue: string) {
    this.dataSource.filter = filterValue;
  }

  /* l'examen n'a pas encore commencer*/
  isNotStart(exam: Exam) {
    return this.checkExamStatus(exam, StatusExamService.AFFECTED);
  }
 /* l'examen en cours*/
  isInProgress(exam: Exam) {
    return this.checkExamStatus(exam, StatusExamService.IN_PROGRESS);
  }
 /* l'examen est finit*/
  isFinish(exam: Exam) {
    return this.checkExamStatus(exam, StatusExamService.FINISHED);
  }
/* verifier le statut de l'examen*/
  checkExamStatus(exam: Exam, statusExam: StatusExam) {
    return exam != null && exam.statutExam.equals(statusExam);
  }

  /* Rafraichir liste des examens pour un recruteur*/
  refreshExamsRecruiter() {
    this.examService.getListForRecruiter(this.signinService.getUser().id).subscribe(exams => {
      this.exams.push(...exams );
      this.dataSource.filter = '';
    });
    this.exams.splice(0, this.exams.length);

  }
  isRecruiter(){
    return this.signinService.getUser().profil.name === 'RECRUTEUR';
  }
}
