import { Component } from '@angular/core';
import { SigninService } from '../auth/signin/signin.service';
import { Router } from '@angular/router';
import { ToolbarService } from './toolbar.service';
import { SidenavService } from '../tools/sidenav.service';
import { User } from '../models/user.model';

@Component({
  selector: 'app-toolbar',
  templateUrl: './toolbar.component.html',
  styleUrls: ['./toolbar.component.scss']
})
export class ToolbarComponent {

  constructor(private toolbarService: ToolbarService, private router: Router, private signinService: SigninService,
              private sidenavService: SidenavService) {}

  getTitle() {
    return this.toolbarService.getTitle();
  }

  isAuth() {
    return this.signinService.isAuth();
  }

  onLogout() {
    this.signinService.logoutWithServerNotified();
    this.router.navigate(['/login']);
  }

  toggleMenu() {
    this.sidenavService.toggleMenu();
  }
  getUser(): User {
    return this.signinService.getUser();
  }
}
