import { Component, OnInit } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { MatDialog } from '@angular/material';
import { Router } from '@angular/router';
import { User } from 'src/app/models/user.model';
import { LoadDialogComponent } from 'src/app/dialog/load-dialog/load-dialog.component';
import { HttpErrorResponse } from '@angular/common/http';
import { LanguageService } from 'src/app/language/language.service';
import { ValidErrorDialogComponent } from 'src/app/dialog/valid-error-dialog/valid-error-dialog.component';
import { RecruiterService } from 'src/app/services/recruteur.service';
@Component({
  selector: 'app-add-recruteur',
  templateUrl: './add-recruteur.component.html',
  styleUrls: ['./add-recruteur.component.scss']
})
export class AddRecruteurComponent implements OnInit {

  cnxForm: FormGroup;
  error: string;
  hasError: boolean;

  constructor(private formBuilder: FormBuilder, private rectuteurService: RecruiterService,
              private router: Router, public dialog: MatDialog, private translate: LanguageService) {}

  ngOnInit() {
    this.cnxForm = this.formBuilder.group({
      name: ['', [Validators.required]],
      firstname: ['', [Validators.required]],
      mail: ['', [Validators.required, Validators.email]],
      phoneNumber: ['', [Validators.required]],
    });
  }

  onSubmit() {
    const dialog = this.dialog.open(LoadDialogComponent, {
      disableClose: true,
      data: {title: this.translate.getWord('register.inProgress')}
    });

    const user = new User();
    user.name = this.cnxForm.get('name').value.toUpperCase();
    user.firstname = this.cnxForm.get('firstname').value;
    user.mail = this.cnxForm.get('mail').value.toLowerCase();
    user.setPhoneNumberFrenchFormat(this.cnxForm.get('phoneNumber').value);
    user.mailValid = true;

    this.rectuteurService.saveRecruteur(user).subscribe(
        (createdUser) => {
          dialog.close();
          this.hasError = false;

          const validDialog = this.dialog.open(ValidErrorDialogComponent, {
            data: {title: this.translate.getWord('register.valid'), state: 1}
          });

          validDialog.afterClosed().subscribe(() => this.router.navigate(['recruiters', createdUser.id]));
        },
        (error: HttpErrorResponse) => {
          if(error && error.status == 409){
            this.dialog.open(ValidErrorDialogComponent, {
              data: {state: -1, title: 'Erreur', description: this.translate.getWord('register.conflict') }
            });
          }
          dialog.close();
          this.error = error.error.message === undefined ? this.translate.getWord('network.error') : error.error.message;
          this.hasError = true;
    });
  }
}
