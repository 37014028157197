import { Component, OnChanges, OnInit, SimpleChanges } from '@angular/core';
import { AbstractControl, FormBuilder, FormGroup, ValidationErrors, ValidatorFn, Validators } from '@angular/forms';
import { EmailService } from 'src/app/services/email.service';
import { MatSnackBar } from '@angular/material/snack-bar';


@Component({
  selector: 'app-reset-password',
  templateUrl: './reset.password.component.html',
  styleUrls: ['./reset.password.component.scss']
})
export class ResetPasswordComponent implements OnInit {
  resetForm: FormGroup
  disableButton: boolean
  email: string
  hasError: boolean
  hasSuccess: boolean

  constructor(private fb: FormBuilder, private emailService: EmailService, private snackBar: MatSnackBar) { }

  ngOnInit(): void {
    this.resetForm = this.fb.group({
      email: ['', [Validators.required, this.customEmailValidator]]
    });
    this.disableButton = false
  }

  onSubmit() {
    const email = this.resetForm.get('email').value;
    this.hasSuccess = true
    this.disableButton = true
    this.emailService.sendResetPasswordEmail(email).subscribe(
      () => {
            this.hasSuccess = true
            this.snackBar.open('email envoyé avec succès!', 'Fermer', {
           duration: 3000, 
         });
       },
      error => {}
    );
  }

  customEmailValidator(): ValidatorFn {
    return (control: AbstractControl): ValidationErrors | null => {
      const emailPattern = new RegExp("^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9]+\.[a-zA-Z]{2,}$"); // Exemple de pattern plus précis
      if (control.value && !emailPattern.test(control.value)) {
        return { 'invalidEmail': { value: control.value } };
      }
      return null;
    };
  }
}
