import { Component } from '@angular/core';
import { SigninService } from '../../auth/signin/signin.service';
import { User } from '../../models/user.model';
import { ProfilService } from 'src/app/services/profil.service';
import { Profil } from 'src/app/models/profil.model';

@Component({
  selector: 'app-dash-board',
  templateUrl: './dash-board.component.html',
  styleUrls: ['./dash-board.component.scss']
})
export class DashBoardComponent {

  constructor(private signinService: SigninService) {}

  getUser(): User {
    return this.signinService.getUser();
  }

  isCandidate() {
    return this.checkGroup(ProfilService.CANDIDATE);
  }

  isRecruiter() {
    return this.checkGroup(ProfilService.RECRUITER);
  }

  isAdmin() {
    return this.checkGroup(ProfilService.ADMIN);
  }

  checkGroup(profil: Profil) {
    return this.getUser().profil.equals(profil);
  }
}
