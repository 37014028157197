import { Component, ViewChild, OnInit } from "@angular/core";
import { ResponsiveService } from "src/app/tools/responsive.service";
import { MatPaginator, MatTableDataSource, MatSort, MatDialog } from "@angular/material";
import { Subscription } from "rxjs";
import { Level } from "src/app/models/level.model";
import { LevelService } from "src/app/services/level.service";
import { Question } from "src/app/models/question.model";
import { QuestionService } from "src/app/services/question.service";
import { Technology } from "src/app/models/technology.model";
import { TechnologyService } from "src/app/services/technology.service";
import { LanguageService } from "src/app/language/language.service";
import { ConfirmationDialogComponent } from "src/app/dialog/confirmation-dialog/confirmation-dialog.component";
import { LoadDialogComponent } from "src/app/dialog/load-dialog/load-dialog.component";
import { ValidErrorDialogComponent } from "src/app/dialog/valid-error-dialog/valid-error-dialog.component";
import { DomSanitizer } from "@angular/platform-browser";
import { HttpErrorResponse } from "@angular/common/http";

@Component({
  selector: "app-list-question",
  templateUrl: "./list-question.component.html",
  styleUrls: ["./list-question.component.scss"],
})
export class ListQuestionComponent implements OnInit {
  public defaultTechnology = new Technology();
  public defaultLevel = new Level();

  private candidatesRequest: Subscription;
  private questions: Question[];

  dataSource: MatTableDataSource<Question>;

  // Filtres
  technologies: Technology[];
  technology: Technology;

  levels: Level[];
  level: Level;

  @ViewChild(MatPaginator, { static: true }) paginator: MatPaginator;
  @ViewChild(MatSort, { static: true }) sort: MatSort;

  hasError: boolean;

  constructor(
    private questionService: QuestionService,
    private levelService: LevelService,
    private technologyService: TechnologyService,
    private responsiveService: ResponsiveService,
    private translate: LanguageService,
    private dialog: MatDialog,
    private sanitizer: DomSanitizer

  ) {
    this.questions = [];
    this.dataSource = new MatTableDataSource(this.questions);
    this.technology = this.defaultTechnology;
    this.level = this.defaultLevel;

    this.technologyService.getList().subscribe((technologies) => {
      this.technologies = technologies;
    });


    this.loadLevels();
    this.refreshQuestionsList();

    this.translate.addWebLanguageChangeListener(() => {
      this.loadLevels();
      this.refreshQuestionsList();
    });
  }

  ngOnInit(): void {
    this.dataSource.paginator = this.paginator;
    this.dataSource.sort = this.sort;
    this.dataSource.filterPredicate = (question: Question, filter: string) => {
      filter = filter.trim().toLowerCase();
      const name = question.question.toLowerCase();

      if (name.search(filter) >= 0) {
        return true;
      }

      return false;
    };

    const defaultSort = this.dataSource.sortingDataAccessor;
    this.dataSource.sortingDataAccessor = (
      question: Question,
      sortHeaderId: string
    ): string | number => {
      let sortAttribut = defaultSort(question, sortHeaderId);

      switch (sortHeaderId) {
        case 'level':
          sortAttribut = question.level.name;
          break;
        case 'technology':
          sortAttribut = question.technology.name;
          break;
      }
      return sortAttribut;
    };
  }

  getQuestionsDisplayedColumns(): string[] {
    return this.responsiveService.isOnPortableScreen()
      ? ["name", "nbPoints", "technology", "level", "type", "etatQuestion", "rateSuccess", "action"]
      : ["name", "nbPoints", "technology", "level", "type", "etatQuestion", "rateSuccess", "action"];
  }

  applyFilter(filterValue: string) {
    this.dataSource.filter = filterValue;
  }

  loadLevels() {
    this.levelService.getList().subscribe((levels) => {
      this.levels = levels;
    });
  }

  refreshQuestionsList() {
    if (this.candidatesRequest) {
      this.candidatesRequest.unsubscribe();
    }

    this.candidatesRequest = this.questionService
      .getList(
        this.level !== this.defaultLevel ? this.level : null,
        this.technology !== this.defaultTechnology ? [this.technology] : null
      )
      .subscribe((questions: Array<Question>) => {
        this.questions.splice(0, this.questions.length);
        this.questions.push(...questions);
        this.dataSource.filter = "";
      });
  }

  resetFilter() {
    this.level = this.defaultLevel;
    this.technology = this.defaultTechnology;
    this.refreshQuestionsList();
  }

  //afficher le tooltip en fonction de l'état de la 
  mouseOver(quetion: Question): string {
    if (quetion.etatQuestion === "active") {
      return "word.disable";
    }
    else if (quetion.etatQuestion === "inactive") {
      return "word.reEnable";
    }
  }

  // switcher l'état de la question active//inactive et vice versa
  onSwitchStateQuestion(question: Question) {
    if (question.etatQuestion === 'active') {
      this.dialog.open(ConfirmationDialogComponent, {
        disableClose: true,
        data: { title: this.translate.getWord('question.disable') }
      }).afterClosed().subscribe(result => {
        if (result) {
          const loadDialog = this.dialog.open(LoadDialogComponent, {
            disableClose: true,
            data: { title: this.translate.getWord('disable.inProgress') }
          });
          this.questionService.switchStateQuestion(question.id).subscribe(() => {
            loadDialog.close();
            this.hasError = false;
            this.dialog.open(ValidErrorDialogComponent, {
              data: { title: this.translate.getWord('disable.valid'), state: 1 }
            })
              .afterClosed().subscribe(() => window.location.reload());
          },
            (error: HttpErrorResponse) => {
              if (error && error.status === 500) {
                this.dialog.open(ValidErrorDialogComponent, {
                  data: { state: -1, title: 'Erreur', description: this.translate.getWord('question.disableError') }
                });
              }
              loadDialog.close();
              this.hasError = true;
            }
          );
        }
      });
    }
    else if (question.etatQuestion === 'inactive') {
      this.dialog.open(ConfirmationDialogComponent, {
        disableClose: true,
        data: { title: this.translate.getWord('question.reEnable') }
      }).afterClosed().subscribe(result => {
        if (result) {
          const loadDialog = this.dialog.open(LoadDialogComponent, {
            disableClose: true,
            data: { title: this.translate.getWord('reEnable.inProgress') }
          });
          this.questionService.switchStateQuestion(question.id).subscribe(() => {
            loadDialog.close();
            this.hasError = false;
            this.dialog.open(ValidErrorDialogComponent, {
              data: { title: this.translate.getWord('reEnable.valid'), state: 1 }
            })
              .afterClosed().subscribe(() => window.location.reload());
          });
        }
      });
    }
  }
//coloration selon les données des cellules dans la table question
  onQuestionColoring(value: string) : any{
    switch (value) {
      case "active":
        return this.sanitizer.bypassSecurityTrustStyle("color: green;");
      case "inactive":
        return this.sanitizer.bypassSecurityTrustStyle("color: red;");
      case "Niveau 1":
        return this.sanitizer.bypassSecurityTrustStyle("color: green;");
      case "Niveau 2":
        return this.sanitizer.bypassSecurityTrustStyle("color: chocolate;");
      case "Niveau 3":
        return this.sanitizer.bypassSecurityTrustStyle("color: red;");
      default:
        return this.sanitizer.bypassSecurityTrustStyle("color: darkgreen;");
    }
  }
  // customiser la pagination dans la table des questions
  nbQuestionsPage: number = 100;
  getPageSizeOptions(): number[] {
    if (this.dataSource.paginator.length > this.nbQuestionsPage){
      return [5, 10, 25, 50, this.nbQuestionsPage,  this.dataSource.paginator.length];
    }
    else{
      return [5, 10, 25, 50,this.nbQuestionsPage];
    }
  }
}