import { Component, OnInit, ViewChild } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { MatDialog, MatSort, MatPaginator, MatTableDataSource } from '@angular/material';
import { Router, ActivatedRoute } from '@angular/router';
import { User } from 'src/app/models/user.model';
import { LoadDialogComponent } from 'src/app/dialog/load-dialog/load-dialog.component';
import { LanguageService } from 'src/app/language/language.service';
import { HttpErrorResponse } from '@angular/common/http';
import { ValidErrorDialogComponent } from 'src/app/dialog/valid-error-dialog/valid-error-dialog.component';
import { ResponsiveService } from 'src/app/tools/responsive.service';
import { Exam } from 'src/app/models/exam.model';
import { UserService } from 'src/app/services/user.service';
import { ConfirmationDialogComponent } from 'src/app/dialog/confirmation-dialog/confirmation-dialog.component';
import { SchoolLevel } from 'src/app/models/schoolLevel.model';
import { ProfessionalExperience } from 'src/app/models/professionalExperience.model';
import { ProfessionalExperienceService } from 'src/app/services/professionalExperience.service';
import { SchoolLevelService } from 'src/app/services/schoolLevel.service';
import { TestAssignmentDialogComponent } from 'src/app/dialog/test-assignment-dialog/test-assignment-dialog.component';
import { ExamService } from 'src/app/services/exam.service';
import { CreateRandomTestDialogComponent } from 'src/app/dialog/create-random-test-dialog/create-random-test-dialog.component';
import { StatusService } from 'src/app/services/status.service';
import { DomSanitizer } from '@angular/platform-browser';

@Component({
  selector: 'app-view-user',
  templateUrl: './view-user.component.html',
  styleUrls: ['./view-user.component.scss']
})
export class ViewUserComponent implements OnInit {

  user: User;
  cnxForm: FormGroup;
  error: string;
  hasError: boolean;

  schoolLevels: SchoolLevel[];
  professionalExperiences: ProfessionalExperience[];

  private exams: Exam[];
  private randomExams: Exam[];
  private selectedExams: Exam[];
  dataSource: MatTableDataSource<Exam>;
  randomDataSource: MatTableDataSource<Exam>;
  selectedExamsDataSource: MatTableDataSource<Exam>;

  @ViewChild(MatPaginator, { static: true }) paginator: MatPaginator;
  @ViewChild(MatSort, { static: true }) sort: MatSort;

  @ViewChild(MatPaginator, { static: true }) selectedExamsPaginator: MatPaginator;
  @ViewChild(MatSort, { static: true }) selectedExamsSort: MatSort;

  activeTab: string;

  constructor(
    private formBuilder: FormBuilder,
    public dialog: MatDialog,
    private translate: LanguageService,
    private route: ActivatedRoute,
    private router: Router,
    private userService: UserService,
    private professionalExperienceService: ProfessionalExperienceService,
    private schoolLevelService: SchoolLevelService,
    private responsiveService: ResponsiveService,
    private examService: ExamService,
    private sanitizer: DomSanitizer
  ) {
    this.exams = [];
    this.randomExams = [];
    this.dataSource = new MatTableDataSource(this.exams);

    this.selectedExams = [];
    this.selectedExamsDataSource = new MatTableDataSource(this.selectedExams);

    translate.addWebLanguageChangeListener(() => this.refreshSelect());
    this.refreshSelect();
  }


  ngOnInit() {
    this.user = new User();
    this.user.id = this.route.snapshot.params.id;

    this.cnxForm = this.formBuilder.group({
      name: ['', [Validators.required]],
      firstname: ['', [Validators.required]],
      mail: ['', [Validators.required, Validators.email]],
      phoneNumber: ['', [Validators.required]],
      schoolLevel: [''],
      professionalExperience: [''],
      note: [''],
    });

    this.refreshUser();

    // Surveillez les changements du formulaire
    this.cnxForm.valueChanges.subscribe(() => {
      this.updated();
    });

    this.dataSource.paginator = this.paginator;
    this.dataSource.sort = this.sort;

    this.selectedExamsDataSource.paginator = this.selectedExamsPaginator;
    this.selectedExamsDataSource.sort = this.selectedExamsSort;

    const defaultSort = this.dataSource.sortingDataAccessor;
    this.dataSource.sortingDataAccessor = (passage: Exam, sortHeaderId: string): string | number => {
      let sortAttribut = defaultSort(passage, sortHeaderId);

      switch (sortHeaderId) {
        case 'name': sortAttribut = passage.test.name; break;
        case 'level': sortAttribut = passage.test.level.name; break;
        case 'datePassage': sortAttribut = passage.dateStartPassage.getTime(); break;
        case 'timePassage': sortAttribut = passage.getTime(); break;
        case 'mark': sortAttribut = passage.mark; break;
      }

      return sortAttribut;
    };

    const selectedExamstSort = this.selectedExamsDataSource.sortingDataAccessor;
    this.selectedExamsDataSource.sortingDataAccessor = (passage: Exam, sortHeaderId: string): string | number => {
      let sortAttribut = selectedExamstSort(passage, sortHeaderId);

      switch (sortHeaderId) {
        case 'name': sortAttribut = passage.test.name; break;
        case 'level': sortAttribut = passage.test.level.name; break;
        case 'datePassage': sortAttribut = passage.dateStartPassage.getTime(); break;
        case 'timePassage': sortAttribut = passage.getTime(); break;
        case 'mark': sortAttribut = passage.mark; break;
      }

      return sortAttribut;
    };
    this.route.url.subscribe(urlSegments => {
      const lastSegment = urlSegments[urlSegments.length - 1].path;
      this.activeTab = lastSegment;
    });
  }

  refreshSelect() {
    this.professionalExperienceService.getList().subscribe(professionalExperiences => {
      this.professionalExperiences = professionalExperiences;
    });

    this.schoolLevelService.getList().subscribe(schoolLevels => {
      this.schoolLevels = schoolLevels;
    });
  }

  updated(): boolean {
    let updated = false;

    if (this.cnxForm.get('name').value !== this.user.name) {
      updated = true;
    }
    if (this.cnxForm.get('firstname').value !== this.user.firstname) {
      updated = true;
    }
    if (this.cnxForm.get('mail').value !== this.user.mail) {
      updated = true;
    }
    if (this.cnxForm.get('phoneNumber').value !== this.user.getPhoneNumberFrenchFormat()) {
      updated = true;
    }
    if (this.cnxForm.get('schoolLevel').value !== this.user.schoolLevel) {
      updated = true;
    }
    if (this.cnxForm.get('professionalExperience').value !== this.user.professionalExperience) {
      updated = true;
    }
    if (this.cnxForm.get('note').value !== this.user.note) {
      updated = true;
    }

    return updated;
  }

  refreshUser() {
    this.userService.getById(this.user.id).subscribe(user => {
      this.user = user;
      this.cnxForm.get('name').setValue(this.user.name);
      this.cnxForm.get('firstname').setValue(this.user.firstname);
      this.cnxForm.get('mail').setValue(this.user.mail);
      this.cnxForm.get('phoneNumber').setValue(this.user.getPhoneNumberFrenchFormat());
      this.cnxForm.get('schoolLevel').setValue(this.user.schoolLevel);
      this.cnxForm.get('professionalExperience').setValue(this.user.professionalExperience);
      this.cnxForm.get('note').setValue(this.user.note);

      this.exams.splice(0, this.exams.length);
      this.exams.push(...user.passages);
      this.exams = this.exams.filter(exam => exam.test.name != 'Test random');
      this.dataSource = new MatTableDataSource(this.exams);
      this.randomExams.splice(0, this.randomExams.length);
      this.randomExams.push(...user.passages);
      // Filtring the random tests
      this.randomExams = this.randomExams.filter(exam => exam.test.name == 'Test random');
      this.randomDataSource = new MatTableDataSource(this.randomExams);
      this.dataSource.filter = '';
    }, (error: HttpErrorResponse) => {
      switch (error.status) {
        case 400:
        case 404:
          this.router.navigate(['users']);
      }
    });
  }

  isSameSchoolLevel(o1: SchoolLevel, o2: SchoolLevel) {
    if (o1 == null || o2 == null) {
      return false;
    }

    return o1.equals(o2);
  }

  isSameProfessionalExperience(o1: ProfessionalExperience, o2: ProfessionalExperience) {
    if (o1 == null || o2 == null) {
      return false;
    }

    return o1.equals(o2);
  }

  getQuestionnairesDisplayedColumns(): string[] {
    return this.responsiveService.isOnTabletScreen()
      ? ['id', 'level', 'mark', 'action']
      : ['id', 'name', 'level', 'datePassage', 'timePassage', 'mark', 'action'];
  }

  getRandomQuestionnairesDisplayedColumns(): string[] {
    return this.responsiveService.isOnTabletScreen()
      ? ['id', 'technology', 'level', 'mark', 'action']
      : ['id', 'technology', 'level', 'mark', 'action'];
  }

  getSelectedExamsDisplayedColumns(): string[] {
    return ['name', 'level', 'action'];
  }

  onSubmit() {
    const dialog = this.dialog.open(LoadDialogComponent, {
      disableClose: true,
      data: { title: this.translate.getWord('update.inProgress') }
    });

    this.user.name = this.cnxForm.get('name').value.toUpperCase();
    this.user.firstname = this.cnxForm.get('firstname').value;
    this.user.mail = this.cnxForm.get('mail').value.toLowerCase();
    this.user.setPhoneNumberFrenchFormat(this.cnxForm.get('phoneNumber').value);
    this.user.schoolLevel = this.cnxForm.get('schoolLevel').value;
    this.user.professionalExperience = this.cnxForm.get('professionalExperience').value;
    this.user.note = this.cnxForm.get('note').value;

    this.userService.update(this.user).subscribe(() => {
      dialog.close();
      this.hasError = false;

      this.dialog.open(ValidErrorDialogComponent, {
        data: { title: this.translate.getWord('update.valid'), state: 1 }
      });
      // Retour à la liste des tests
      this.router.navigate(['users']);
    },
      (error: HttpErrorResponse) => {
        dialog.close();
        this.error = error.error.message === undefined ? this.translate.getWord('network.error') : error.error.message;
        this.hasError = true;
      });
  }

  viewExam(id: number) {
    this.router.navigate(['exams', id]);
  }

  onDeleteExam(id: number) {
    this.dialog.open(ConfirmationDialogComponent, {
      disableClose: true,
      data: { title: this.translate.getWord('examen.deleteInProgress') }
    })
      .afterClosed().subscribe(result => {
        if (result) {
          const loadDialog = this.dialog.open(LoadDialogComponent, {
            disableClose: true,
            data: { title: this.translate.getWord('delete.inProgress') }
          });

          this.examService.delete(id).subscribe(() => {
            loadDialog.close();
            this.hasError = false;

            this.dialog.open(ValidErrorDialogComponent, {
              data: { title: this.translate.getWord('delete.valid'), state: 1 }
            })
              .afterClosed().subscribe(() => this.refreshUser());
          },
            (error: HttpErrorResponse) => {
              loadDialog.close();
              this.error = error.error.message === undefined ? this.translate.getWord('network.error') : error.error.message;
              this.hasError = true;
              this.dialog.open(ValidErrorDialogComponent, {
                data: { title: this.translate.getWord('examen.deleteError'), state: -1 }
              })
            });
        }
      });
  }

  onAssignButton() {
    //open loading dialog
    const dialogRef = this.dialog.open(TestAssignmentDialogComponent, {
      data: {
        user: this.user,
        loading: true
      }
    })
    // bulk assign tests
    this.examService.bulkSave(this.selectedExams).subscribe(() => {
      dialogRef.close();
      this.selectedExams = [];
      this.selectedExamsDataSource.data = this.selectedExams;
      this.dialog.open(ValidErrorDialogComponent, {
        data: { title: this.translate.getWord('test.addtest'), state: 1 }
      })
        .afterClosed().subscribe(() => this.refreshUser())
    });
  }

  onSelectTest() {
    this.dialog.open(TestAssignmentDialogComponent, {
      data: {
        user: this.user,
        selectedExams: this.selectedExams
      }
    })
      .afterClosed().subscribe((exam) => {
        if (exam) {
          this.selectedExams.push(exam);
          this.selectedExamsDataSource.data = this.selectedExams;
        }
      });
  }

  onCreateRandomTest() {
    this.dialog.open(CreateRandomTestDialogComponent, {
      data: {
        user: this.user,
        selectedExams: this.selectedExams
      }
    })
      .afterClosed().subscribe((exam) => {
        if (exam) {
          this.selectedExams.push(exam);
          this.selectedExamsDataSource.data = this.selectedExams;
        }
      });
  }

  withdrawExam(exam: Exam) {
    const index = this.selectedExams.findIndex((v) => v === exam);
    this.selectedExams.splice(index, 1);
    this.selectedExamsDataSource.data = this.selectedExams;
  }

  backToPage() {
    if (history.length > 1) {
      history.back();
    } else {
      window.close();
    }
  }

  getLevel(questions) {
    // Getting the levels
    let levels = questions.map(question => question.level.name);
    // Removing the duplicated elements
    levels = [...new Set(levels)];
    return levels.join(', ');
  }

  getDuration(questions) {
    const sum: number = questions.reduce((accumulator, question) => accumulator + question.estimatedTime, 0);
    let result = Math.floor(sum / 60) + ' min ' + Math.floor(sum % 60) + ' sec';
    return result;
  }

  getTechnologies(test: any) {
    let technos = test.questions.map(question => question.technology.name);
    let technosUnique = [...new Set(technos)];
    return technosUnique.join(' - ');
  }

  note: string = null;
  onMarkColoring(examen: Exam): any {
    let examStatus: string = examen.statutExam.name;
    if (examStatus === "Fini") {
      this.note = `${examen.mark}%`
      let mark = examen.mark;
      if (mark >= 0 && mark < 30) {
        return this.sanitizer.bypassSecurityTrustStyle("font-weight:bold; color: red;");
      }
      if (mark >= 30 && mark < 50) {
        return this.sanitizer.bypassSecurityTrustStyle("font-weight:bold; color: chocolate;");
      }
      if (mark >= 50 && mark < 60) {
        return this.sanitizer.bypassSecurityTrustStyle("font-weight:bold; color: olivedrab;");
      }
      if (mark >= 60) {
        return this.sanitizer.bypassSecurityTrustStyle("font-weight:bold; color: green;");
      }
    }
    else if (examStatus === "Affecté") {
      return this.sanitizer.bypassSecurityTrustStyle("color: gray;");
    }
    else if (examStatus === "Supprimé") {
      return this.sanitizer.bypassSecurityTrustStyle("font-weight:bold; color: red;");
    }
  }
}
