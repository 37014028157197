import { QuestionSchema } from './interface/question.model';

export class Question extends QuestionSchema {

    getMinutes() {
        return Math.floor(this.estimatedTime / 60);
    }

    getSeconds() {
        return this.estimatedTime % 60;
    }

    setMinutes(minutes: number) {
        this.estimatedTime = this.getSeconds() + minutes * 60;
    }

    getQuestion(): string {
        return this.question ? `${this.question}` : null;
    }

    setSeconds(seconds: number) {
        if (seconds >= 60) {
            throw new Error('Les secondes ne doivent pas être supérieur ou égale à 60');
        }

        this.estimatedTime = this.estimatedTime - this.getSeconds() + seconds;
    }

    equals(question: Question): boolean {
        return this.id === question.id;
    }

    getQuestionName(): string {
        return this.question ? `${this.question}` : null;
    }

    getType(): string {
        return this.type ? `${this.type}` : null;
    }

    getEtatQuestion(): string {
        return this.etatQuestion? `${this.etatQuestion}`:null;
    }
}
