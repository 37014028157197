import { Component, ViewChild, OnInit } from '@angular/core';
import { User } from 'src/app/models/user.model';
import { ResponsiveService } from 'src/app/tools/responsive.service';
import { MatPaginator, MatTableDataSource, MatSort, MatDialog } from '@angular/material';
import { Subscription } from 'rxjs';
import { UserService } from 'src/app/services/user.service';
import { StatusService } from 'src/app/services/status.service';
import { ConfirmationDialogComponent } from 'src/app/dialog/confirmation-dialog/confirmation-dialog.component';
import { LanguageService } from 'src/app/language/language.service';
import { RecruiterService } from 'src/app/services/recruteur.service';
import { LoadDialogComponent } from 'src/app/dialog/load-dialog/load-dialog.component';
import { Router } from '@angular/router';
import { HttpErrorResponse } from '@angular/common/http';
import { ValidErrorDialogComponent } from 'src/app/dialog/valid-error-dialog/valid-error-dialog.component';
@Component({
  selector: 'app-recruteur',
  templateUrl: './recruteur.component.html',
  styleUrls: ['./recruteur.component.scss']
})
export class RecruteurComponent implements OnInit {


  private recrutersRequest: Subscription;
  private recruters: User[];

  dataSource: MatTableDataSource<User>;
  // Filtres
  freshnessFilter = 0;

  error: string;
  hasError: boolean;

  @ViewChild(MatPaginator, { static: true }) paginator: MatPaginator;
  @ViewChild(MatSort, { static: true }) sort: MatSort;

  constructor(
    private recrutersService: UserService,
    private responsiveService: ResponsiveService,
    private dialog: MatDialog,
    private translate: LanguageService,
    private recruiterService: RecruiterService,
    private router: Router
  ) {
    this.recruters = [];
    this.dataSource = new MatTableDataSource(this.recruters);

    this.refreshrecrutersList();
  }

  ngOnInit(): void {
    this.dataSource.paginator = this.paginator;
    this.dataSource.sort = this.sort;

    this.dataSource.filterPredicate = (user: User, filter: string) => {
      filter = filter.trim().toLowerCase();

      const name = user.name.toLowerCase();
      if (name.startsWith(filter)) {
        return true;
      }

      const firstname = user.firstname.toLowerCase();
      if (firstname.startsWith(filter)) {
        return true;
      }

      if ((name + ' ' + firstname).startsWith(filter)) {
        return true;
      }

      if ((firstname + ' ' + name).startsWith(filter)) {
        return true;
      }

      if (user.getPhoneNumberFrenchFormat().startsWith(filter)) {
        return true;
      }

      return false;
    };

    const defaultSort = this.dataSource.sortingDataAccessor;
    this.dataSource.sortingDataAccessor = (user: User, sortHeaderId: string): string | number => {
      const sortAttribut = defaultSort(user, sortHeaderId);

      return sortAttribut;
    };
  }

  getrecrutersDisplayedColumns(): string[] {
    return this.responsiveService.isOnPortableScreen()
      ? ['name', 'firstname', 'phoneNumber', 'action']
      : ['name', 'firstname', 'phoneNumber', 'action'];
  }

  applyFilter(filterValue: string) {
    this.dataSource.filter = filterValue;
  }

  refreshrecrutersList() {
    if (this.recrutersRequest) {
      this.recrutersRequest.unsubscribe();
    }

    this.recrutersRequest = this.recrutersService.getRecruters(StatusService.VALIDATED, true,
      this.freshnessFilter,).subscribe(users => {
        this.recruters.splice(0, this.recruters.length);
        this.recruters.push(...users);
        this.dataSource.filter = '';
      });
  }

  onHoverMarkInformation(event: MouseEvent) {
    const target = $(event.target);
    const tooltip = target.parent().children('.evolvedTooltip');

    tooltip.show();

    tooltip.css('top', event.clientY);
    tooltip.css('left', event.clientX);
  }

  onOutMarkInformation(event: MouseEvent) {
    $(event.target).parent().children('.evolvedTooltip').hide();
  }

  onSuspendRecruiter(user: User) {
    this.dialog.open(ConfirmationDialogComponent, {
      disableClose: true,
      data: { title: this.translate.getWord('recruiter.suspend') }
    })
      .afterClosed().subscribe(result => {
        if (result) {
          const loadDialog = this.dialog.open(LoadDialogComponent, {
            disableClose: true,
            data: { title: this.translate.getWord('suspend.inProgress') }
          });

          user.status = StatusService.SUSPENDED;
          this.recruiterService.suspendRecruiter(user).subscribe(() => {
            loadDialog.close();
            this.dialog.open(ValidErrorDialogComponent, {
              data: { title: this.translate.getWord('suspend.valid'), state: 1 }
            })
              .afterClosed().subscribe(() => window.location.reload());
          },
            (error: HttpErrorResponse) => {
              loadDialog.close();
              this.error = error.error.message === undefined ? this.translate.getWord('network.error') : error.error.message;
              this.hasError = true;
            });
        }
      });
  }

  // customiser la pagination dans la table des recruteurs
  nbRecruitersPage: number = 100;
  getPageSizeOptions(): number[] {
    if (this.dataSource.paginator.length > this.nbRecruitersPage){
      return [5, 10, 25, 50, this.nbRecruitersPage, this.dataSource.paginator.length];
    }
    else{
      return [5, 10, 25, 50, this.nbRecruitersPage];
    }
  }
}
